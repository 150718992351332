import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import styled from "@emotion/styled";
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import gift from "../img/gift.jpg";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { MONGO_URL} from "./constants";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(43,0,77,0.95)',
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const backgroundImageStyles = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "auto",
  zIndex: "-1",
};

const StyledLink = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
});

const PurchaseButton = styled('button')({
  marginTop: '10px',
  marginRight: '5px',
  padding: '5px 10px',
  backgroundColor: 'green',
  color: 'white',
  border: '1px solid black',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '0.9rem',
});

const TagButton = styled('button')(({ selected }) => ({
  marginTop: '10px',
  marginRight: '5px',
  padding: '5px 10px',
  backgroundColor: selected ? 'blue' : 'navy',
  color: 'white',
  border: '1px solid black',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '0.9rem',
}));

const Registry = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [registryData, setRegistryData] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [purchasedItems, setPurchasedItems] = useState(new Set());
  const [showPurchased, setShowPurchased] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [maxPrice, setMaxPrice] = useState(0);
  const [sliderMax, setSliderMax] = useState(0);
  const [sliderMin, setSliderMin] = useState(0);

  useEffect(() => {
    const fetchRegistryData = async () => {
      try {
        const res = await axios.get(`${MONGO_URL}/registry`);
        const fetchedData = res.data.map(item => ({
          ...item,
          purchased: item.purchased || false,
        }));

        setRegistryData(fetchedData);

        const initialPurchasedItems = new Set(
            fetchedData
                .map((item, index) => (item.purchased ? index : null))
                .filter(index => index !== null)
        );
        setPurchasedItems(initialPurchasedItems);

        const prices = res.data.map(item => item.gbPrice);
        setSliderMax(Math.ceil(Math.max(...prices)));
        setSliderMin(Math.min(...prices));
        setMaxPrice(Math.ceil(Math.max(...prices)));
      } catch (error) {
        console.error("Error fetching registry data:", error);
      }
    };

    fetchRegistryData();
  }, []);

  const handleDialogOpen = (index) => {
    setCurrentIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    setDialogOpen(false);
    if (confirmed && currentIndex !== null) {
      setPurchasedItems((prev) => new Set(prev).add(currentIndex));

      const currentItem = registryData[currentIndex];

      try {
        await axios.post(`${MONGO_URL}/updateRegistry`, {
        id: currentItem._id
      });

    } catch (error) {
      console.error("Error updating purchase status in MongoDB:", error);
    }
  }
  setCurrentIndex(null);
};

  const filterData = () => {
    return registryData
        .map((item, originalIndex) => ({ ...item, originalIndex }))
        .filter(({ tags, gbPrice, originalIndex }) => {
          const isPurchased = purchasedItems.has(originalIndex);
          return (showPurchased || !isPurchased) &&
              (!selectedTag || tags.includes(selectedTag)) &&
              gbPrice <= maxPrice;
        })
        .sort((a, b) => a.title.localeCompare(b.title)); // Sort items alphabetically by title
  };

  const filteredData = filterData();

  const handleTagClick = (tag) => {
    if (tag !== "Purchased") {
      setSelectedTag(prev => (prev === tag ? null : tag));
    }
  };

  return (
      <div style={{paddingTop: "60px"}}>
        <img src={gift} className="HomeImageDesktop" alt="A gift" style={backgroundImageStyles}/>
        <img src={gift} className="HomeImageMobile" alt="A gift" style={backgroundImageStyles}/>

        <div className="text-container">
          <CssBaseline/>
          <Card
              variant="outlined"
              sx={{
                paddingLeft: "5px", paddingRight: "5px",
                marginLeft: "2%", marginRight: "2%",
                marginBottom: "10px",
                marginTop: "15px"
              }}
              style={{backgroundColor: "rgba(10,30,104,0.6)"}}
          >
            <Typography variant="h5" component="h1" color={"ivory"} gutterBottom align={"center"}
                        padding={"10px"} fontFamily={"Goudy"}>
              Your presence in London on our big day is a gift in itself and we are really excited to have the
              opportunity to celebrate
              with all of you. If you also wish to give us a gift to celebrate the occasion of our marriage then there
              are a couple of ways in which you can do so.
              <br /> - Any monetary gifts will be used towards our dream honeymoon,
              planned for our first anniversary in 2026.
              <br /> - We have also curated a list of items that we would love to
              receive below that you may want to consider.
              <br /> Whatever you decide or feel comfortable with, thank you for
              celebrating with us!
            </Typography>
            <Typography variant="h6" component="h1" color={"ivory"} gutterBottom align={"center"}
                        padding={"10px"} fontFamily={"Goudy"}>
              If you do decide to buy something from this list then please mark it as
              purchased to prevent duplicates. Also, we may not be able to open all the presents on the day, so please
              ensure you include your name with any gifts you bring so that we can thank you properly after the wedding!
              <br /> If you find a similar item that you think we will love then feel free to reach out to us.
            </Typography>
          </Card>
        </div>

        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "15px",
          marginRight: "25px"
        }}>
          <span style={{marginRight: "10px"}}>Max Price: £{maxPrice}</span>
          <Slider
              value={maxPrice}
              onChange={(e, newValue) => setMaxPrice(newValue)}
              min={sliderMin}
              max={sliderMax}
              step={1}
              style={{width: "200px"}}
              aria-labelledby="max-price-slider"
          />
        </div>

        <div style={{marginBottom: "15px", display: "flex", alignItems: "center"}}>
          <Checkbox
              checked={showPurchased}
              onChange={(e) => setShowPurchased(e.target.checked)}
              color="primary"
          />
          <span>Show Purchased Items</span>
        </div>

        {selectedTag && (
            <TagButton
                onClick={() => setSelectedTag(null)}
                selected={true}
                style={{marginBottom: '15px'}}
            >
              {selectedTag} (Click to remove)
            </TagButton>
        )}

        <Grid container spacing={isMobile ? 4 : 10} paddingTop={isMobile ? 5 : 10} paddingLeft={isMobile ? 2 : 5}
              paddingRight={isMobile ? 2 : 5}>
          {filteredData.map(({link, imgSrc, title, description, price, tags, purchased, originalIndex}) => (
              <Grid key={originalIndex} xs={12} sm={6} md={4}>
                <Item>
                  <StyledLink href={link} target="_blank" rel="noopener noreferrer">
                    <img src={imgSrc} alt={title}
                         style={{width: "100%", maxHeight: "500px", objectFit: "cover", borderRadius: "8px"}}/>
                    <span
                        style={{color: "ivory", fontWeight: "bold", display: "block", marginTop: "10px"}}>{title}</span>
                    <span style={{color: "ivory", display: "block", marginTop: "5px"}}>{description}</span>
                    <span style={{color: "ivory", display: "block", marginTop: "5px"}}>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: tags.includes('Indian') ? 'INR' : 'GBP',
                        minimumFractionDigits: price % 1 ? 2 : 0,
                      }).format(price)}
                    </span>
                    <div>
                      {tags.concat(purchasedItems.has(originalIndex) ? 'Purchased' : '')
                          .sort((a, b) => a.localeCompare(b))
                          .map((tag, i) => (
                              tag && (
                                  <TagButton
                                      key={i}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleTagClick(tag.trim());
                                      }}
                                      selected={selectedTag === tag}
                                      style={{
                                        cursor: tag === 'Purchased' ? 'default' : 'pointer',
                                        textDecoration: tag === 'Purchased' ? 'none' : 'underline',
                                      }}
                                  >
                                    {tag.trim()}
                                  </TagButton>
                              )
                          ))}
                    </div>
                  </StyledLink>
                  {!purchasedItems.has(originalIndex) && (
                      <PurchaseButton onClick={() => handleDialogOpen(originalIndex)}>
                        Mark as Purchased
                      </PurchaseButton>
                  )}
                </Item>
              </Grid>
          ))}
        </Grid>

        <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
          <DialogTitle>Confirm Purchase</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to mark this item as purchased?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialogClose(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default Registry;
