import logo from "../logo.svg";
import Main from "./Main";
import "../styles/SiteActive.css";
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

function SiteActive(props) {
    const siteActive = props.siteActive;


    if(siteActive) {
        return <I18nextProvider i18n={i18n}><Main fullGuest={props.fullGuest}/></I18nextProvider>
    }
    else {
        return <div className='SiteActive-Container'>
            <img src={logo} alt="logo" className='Logo'/>
            <div className='ComingSoon'>
                <p>Coming Soon</p>
            </div>
        </div>
    }
}

export default SiteActive
