import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import clothes from "../img/clothes.jpg";
import colours from "../img/colours.png";

const backgroundImageStyles = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "auto",
    zIndex: "-1",
};

const cardStyles = {
    padding: "15px",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "90px",
    backgroundColor: "rgba(10,30,104,0.6)",
    overflow: "auto",
    height: "auto"
};

const textStyles = {
    padding: "20px",
    textAlign: "center"
};

const imageStyles = {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%'
};

function ReceptionClothes() {
    return (
        <div className="scrolling-container">
            <img src={clothes} className="HomeImageDesktop" alt="Daniel and Siva at Happiness Forgets" style={backgroundImageStyles}/>
            <img src={clothes} className="HomeImageMobile" alt="Daniel and Siva at Happiness Forgets" style={backgroundImageStyles}/>

            <div className="text-container">
                <CssBaseline/>
                <Card variant="outlined" sx={cardStyles}>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        These is some of the information we have given to guests attending the ceremony in the day. For evening guests there are no such requirements other than we ask that you dress smartly for photos.
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Men
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        A button-up shirt or kurta, long trousers or a veshti/dhoti, covered formal shoes. You may wish
                        to wear a tie or suit jacket.<br/>
                        No jeans or shorts. No sandals or trainers/sneakers/running shoes. No T-shirts.
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Women
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        A dress or sari. You may wish to wear a fascinator. You are also welcome to wear a shirt/suit if
                        you prefer.<br/>
                        Please do not wear white, it is considered a faux pas. Please do not wear red as Siva will be
                        wearing red.
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Everyone
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        If you have other national dress that you would like to wear then you are welcome to do so.<br/>
                        We have a colour scheme that you are welcome to follow if you choose:
                    </Typography>
                    <img src={colours} alt="Colour scheme" style={imageStyles}/>
                    <Typography variant="h5" component="h2" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        {'Please reach out to us with any questions on what to wear. We are happy to help!'}
                    </Typography>
                </Card>
            </div>
        </div>
    );
}

export default ReceptionClothes;
