import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import styled from "@emotion/styled";
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import london from "../img/london.jpg";
import londonSmall from "../img/london-small.jpg";
import { recommendationData } from "./RecommendationData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(43,0,77,0.95)',
  textAlign: 'center',
  padding: theme.spacing(2),  // Adjust padding based on theme for better spacing control
}));

const Image = styled('img')(({ theme }) => ({
  width: "100%",
  maxHeight: "200px",  // Restrict the height for consistency
  objectFit: "cover",  // Ensure images fit well within their containers
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    maxHeight: "150px",  // Smaller height for mobile
  },
}));

const Title = styled('span')(({ theme }) => ({
  color: "ivory",
  fontWeight: "bold",
  fontFamily: "Goudy",
  fontSize: "1.2rem",
  display: "block",
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: "1rem",
  },
}));

const Description = styled('span')(({ theme }) => ({
  color: "ivory",
  fontFamily: "Goudy",
  fontSize: "1rem",
  display: "block",
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: "0.875rem",
  },
}));

const backgroundImageStyles = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "auto",
  zIndex: "-1",
};

const StyledLink = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
});

function Recommendations() {
  const isMobile = useMediaQuery('(max-width:600px)');  // Hook to detect mobile screens

  return (
      <div className="form-container">
        <img src={london} className="HomeImageDesktop" alt="London by night" style={backgroundImageStyles}/>
        <img src={londonSmall} className="HomeImageMobile" alt="London by night" style={backgroundImageStyles}/>

        <Grid
            container
            spacing={isMobile ? 4 : 10}  // Adjust grid spacing for mobile
            paddingTop={isMobile ? 5 : 10}  // Adjust padding for mobile
            paddingLeft={isMobile ? 2 : 5}
            paddingRight={isMobile ? 2 : 5}
        >
          {recommendationData.map(({ link, imgSrc, title, description }, index) => (
              <Grid key={index} xs={12} sm={6} md={4}>  {/* Responsive column sizes */}
                <Item>
                  <StyledLink href={link} target="_blank" rel="noopener noreferrer">
                    <Image src={imgSrc} alt={title}/>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                  </StyledLink>
                </Item>
              </Grid>
          ))}
        </Grid>
      </div>
  );
}

export default Recommendations;
