import React from 'react';
import Home from './Home';
import RSVP from './RSVP';
import Recommendations from './Recommendations';
import IvoryButton from "./IvoryButton";
import Details from "./Details";
import Clothes from "./Clothes";
import Registry from "./Registry";
import ReceptionRSVP from './ReceptionRSVP';
import ReceptionDetails from './ReceptionDetails';
import ReceptionClothes from './ReceptionClothes';
import { useTranslation } from 'react-i18next';

const buttonConfigs = [
    { label: 'details', component: 'details', bottom: '50%' },
    { label: 'rsvp', component: 'rsvp', bottom: '40%' },
    { label: 'clothes', component: 'clothes', bottom: '30%' },
    { label: 'registry', component: 'registry', bottom: '20%' },
    { label: 'recommendations', component: 'recommendations', bottom: '10%' }
];

function Main(props) {
    const { t, i18n } = useTranslation();
    const [currentComponent, setCurrentComponent] = React.useState('home');
    const [language, setLanguage] = React.useState('en'); // Track current language
    const fullGuest = props.fullGuest;

    const getComponent = () => {
        switch (currentComponent) {
            case 'home':
                return <Home />;
            case 'rsvp':
                return fullGuest ? <RSVP /> : <ReceptionRSVP />;
            case 'recommendations':
                return <Recommendations />;
            case 'details':
                return fullGuest ? <Details /> : <ReceptionDetails />;
            case 'registry':
                return <Registry />;
            case 'clothes':
                return fullGuest ? <Clothes /> : <ReceptionClothes />;
            default:
                return <Home />;
        }
    };

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'ta' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
      };

    return (
        <div className="main-container">
            <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <button onClick={toggleLanguage}>
                {language === 'en' ? 'தமிழ்' : 'English'}
                </button>
            </div>
            {currentComponent === "home" ? (
                <div>
                    <div className='HomeImage'>
                        {buttonConfigs.map(({ label, component, bottom }) => (
                            <IvoryButton
                                key={component}
                                variant="outlined"
                                sx={{ position: "absolute", left: "80%", bottom }}
                                onClick={() => setCurrentComponent(component)}
                            >
                                {t(label)}
                            </IvoryButton>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="button-container">
                    <IvoryButton
                        key='home'
                        variant="outlined"
                        onClick={() => setCurrentComponent('home')}
                    >
                        {t('home')}
                    </IvoryButton>
                    {buttonConfigs.map(({ label, component }) => (
                        <IvoryButton
                            key={component}
                            variant="outlined"
                            onClick={() => setCurrentComponent(component)}
                            sx={{ position: "relative", marginLeft: "10px"}}
                        >
                            {t(label)}
                        </IvoryButton>
                    ))}
                </div>
            )}

            <div className="component-container">
                {getComponent()}
            </div>
        </div>
    );
}

export default Main;
