import rsvp from "../img/rsvp.jpg";
import rsvpSmall from "../img/rsvp-small.jpg";
import React from "react";
import FormfacadeEmbed from "@formfacade/embed-react";
import "../styles/RSVP.css"

function ReceptionRSVP() {
    return (
        <div className="container">
            <img src={rsvp} className="HomeImageDesktop" alt="London by night" />
            <img src={rsvpSmall} className="HomeImageMobile" alt="London by night"/>
            <div className="form-container">
                <FormfacadeEmbed
                    formFacadeURL="https://formfacade.com/include/112949698534543051314/form/1FAIpQLSfp786Q5T88DucGmFaJX5E7tCfR6O9qjubRl4hfokIU75iIAg/classic.js/?div=ff-compose"
                    onSubmitForm={() => console.log('Form submitted')}
                />
            </div>
        </div>
    );
}

export default ReceptionRSVP;